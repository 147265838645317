<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Formulario Nuevo Ciclo
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >


        <!-- From -->
        <b-form-group
          label="Fecha"
          label-for="fecha"
        >
        <div class="d-flex align-items-center">

        <feather-icon icon="CalendarIcon" size="16" />
            <flat-pickr v-model="rangePicker" :config="{ mode: 'range' }"
              class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD"
              style="width: 55% !important;" />
              </div>
        </b-form-group>

        <!-- From -->
        <b-form-group
          label="Elaborado Por"
          label-for="from"
        >
        
          <b-form-input
            id="from"
            v-model="userData.fullName"
            trim
            type="email"
          />
        </b-form-group>


        <!-- Message -->
        <b-form-group
          label="Observaciones"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="sendInvoiceData.message"
            rows="12"
            trim
          />
        </b-form-group>

        <!-- ? File/Attachement -->
        <b-badge variant="light-primary">
          <feather-icon icon="LinkIcon" />
          <span class="ml-50">Invoice Attached</span>
        </b-badge>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="createNewCycle"
          >
            Crear Ciclo
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="cancel"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, 
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, flatPickr
  },
  directives: {
    Ripple,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const sendInvoiceData = ref({
      from: '',
      to: '',
      subject: '',
      message: `Ninguna`,
      // ? Need to handle how you want to send item
      // * You can also send link to download invoice in message
    })

    return {
      sendInvoiceData, userData
    }
  },
  methods: {

    async createNewCycle() {

      console.log('plastico ', {
        owner: this.userData,
        cycleResume: this.sendInvoiceData
      }
        );

      this.$emit('createNewCycle', {
        owner: this.userData,
        cycleResume: this.sendInvoiceData
      });
    },
    cancel() {
      this.$emit('cancelNewCycle', {})
    }
  }
}
</script>

<style>

</style>
